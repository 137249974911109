.header {
  margin-bottom: 5rem;

  &__btn {
    margin-left: auto;
    padding: 0.5em 1.8em;
    width: 10rem;
    font-size: 1.6rem;
  }

  &__nav-link {
    margin-right: auto;
    padding: 0.5em 1.8em;
    width: 10rem;
    font-size: 1.6rem;
  }
}