.form-wrapper {
  font-size: 1.2rem;
}

.login-form__btn {
  margin-bottom: 1rem;
  width: 100%;
  font-size: 1.6rem;
}

.invalid-feedback {
  display: block;
}