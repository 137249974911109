.movie-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #fff;
  padding-bottom: 1rem;
  height: 100%;
  font-size: 1.4rem;

  &__info-box {
    margin-top: 1.5rem;
  }

  &__title {
    font-size: 1.6rem;
    text-transform: capitalize;
  }

  &__link {
    color: #fff;
    transition: all 0.2s ease-in-out;

    &:hover {
      text-decoration: none;
      color: #d27d00;
    }
  }

  &__button-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__rating-box {
    display: flex;
  }

  &__rating {
    margin-left: 0.3em;
    line-height: 1.4;
  }
}