.pagination {
  &__button {
    padding: 0.5em 0;
    width: 8rem;
    font-size: 1.2rem;

    &:disabled {
      border: 1px solid #fff;
      box-shadow: none;
      cursor: default;
    }
  }

  &__text {
    font-size: 1.2rem;
  }
}