.filters {
  &__wrapper {
    border: 1px solid #fdfdfd;
    padding: 3rem 2rem;
  }

  &__title {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    text-transform: uppercase;
  }
}