* {
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
}

html {
  box-sizing: border-box;
  font-size: 10px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  padding: 2em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: #fff;
  background-color: #463a3a;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}
