.error-fallback-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.error-fallback {
  font-size: 14px;
  color: #fff;
}