.btn {
  display: block;
  border: 1px solid #fff;
  border-radius: 0;
  color: #fff;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:active,
  &.__active,
  &:focus {
    outline: none;
    border: 1px solid #fff;
    box-shadow: 0 5px 10px rgba(#d27d00, 0.2);
    background: #d27d00;
  }

  &:hover,
  &.__hover {
    border: 1px solid #d27d00;
    box-shadow: 0 10px 20px rgba(#d27d00, 0.2);
    color: #fff;
  }
}

.mr {
  margin-right: 1rem;
}

.icon-button {
  margin-left: 0.5em;
  border: none;
  color: #fff;
  background-color: #463a3a;
  transition: all 0.2s;

  &:hover {
    color: #d27d00;
  }
}