.genres {
  margin-bottom: 2.5rem;

  &__label {
    margin-left: 1rem;
    font-size: 1.2rem;
  }

  &__checkbox-wrapper {
    margin-bottom: 0.7rem;
  }
}
